import React from "react"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container'>
          <div className='box logo'>
            <img className="logoimg" src='../images/logooq.png' alt='' />
            <p>Qo'shimcha taklif va hamkorlik bo'yicha murojaat qilishingiz mumkin</p>
            <i className='fa fa-envelope'></i>
            <span> support@xalqdardi.uz </span> <br />
            <i className='fa fa-headphones'></i>
            <span> +998 90 308-00-51</span>
          </div>
          <div className='box'>
            <h3>SPORT</h3>
            <div className='item'>
              <img src='../images/hero/hero1.jpg' alt='' />
              <p>Google bir necha kun ichida Android xavfsizligini kuchaytiradi</p>
            </div>
            <div className='item'>
              <img src='../images/hero/hero2.jpg' alt='' />
              <p>Cespedes g'alaba qozongan beysbol o'yinini o'ynaydi</p>
            </div>
          </div>
          <div className='box'>
            <h3>Kriket</h3>
            <div className='item'>
              <img src='../images/hero/hero3.jpg' alt='' />
              <p>AQSh Intelga askarlarni topish uchun yordam berishga va'da berdi</p>
            </div>
            <div className='item'>
              <img src='../images/hero/hero1.jpg' alt='' />
              <p>Qayta tiklanadigan energiya sanoat siyosatni kutayotganda o'ldi</p>
            </div>
          </div>
          <div className='box'>
            <h3>YORLIQLAR</h3>
            {/*<i className='fa fa-chevron-right'></i>*/}
            <ul>
              <li>
                <span>Boks</span> <label>(5)</label>
              </li>
              <li>
                <span>Moda</span> <label>(6)</label>
              </li>
              <li>
                <span>Salomatlik</span> <label>(7)</label>
              </li>
              <li>
                <span>Tabiat</span> <label>(9)</label>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal  '>
        <div className='container flexSB'>
          <p>© Barcha huquqlar himoyalangan</p>
          <p>
          <i className='fa fa-heart'></i> Xalq Dardi tomonidan
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
