import React from "react"
import "./side.css"
import Slider from "react-slick"
import Heading from "../../../common/heading/Heading"
import { gallery } from "../../../../dummyData"
import Tpost from "../Tpost/Tpost"
import SocialMedia from "../social/SocialMedia"

//const allCat = [...new Set(popular.map((curEle) => curEle.catgeory))]
//console.log(allCat)

const SidePage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const catgeory = ["Dunyo", "Sayohat", "Sport", "qiziqarli", "salomatlik", "moda", "biznes", "texnologiya"]
  return (
    <>
      <Heading title='Ijtimoiy tarmoqlar' />
      <SocialMedia />

      <Heading title='Email qoldiring' />

      <section className='subscribe'>
        <h1 className='title'>Yangi hikoyalarimizga obuna bo'ling</h1>
        <form action=''>
          <input type='email' placeholder='Email Address...' />
          <button>
            <i className='fa fa-paper-plane'></i> Yuborish
          </button>
        </form>
      </section>

      {/* <section className='banner'>
        <img src='./images/sidebar-banner-new.jpg' alt='' />
      </section> */}

      <Tpost />

      {/* <section className='catgorys'>
        <Heading title='Menyular' />
        <div className='items'>{allCat}</div>
        {catgeory.map((val) => {
          return (
            <div className='category category1'>
              <span>{val}</span>
            </div>
          )
        })}
      </section> */}

      <section className='gallery'>
        <Heading title='Rasmlar' />
        <Slider {...settings}>
          {gallery.map((val) => {
            return (
              <div className='img'>
                <img src={val.cover} alt='' />
              </div>
            )
          })}
        </Slider>
      </section>
    </>
  )
}

export default SidePage
