import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ppost } from "../../dummyData"
import SidePage from "../home/sideContent/side/SidePage"
import "../home/mainContent/homes/style.css"
import "./ppostpage.css"
import "../home/sideContent/side/side.css"
import SinglePageSlider from "./slider/SinglePageSlider"

const PpostPage = () => {
  const { id } = useParams()
  const [item, setItem] = useState(null)

  useEffect(() => {
    const item = ppost.find((item) => item.id === parseInt(id))
    window.scrollTo(0, 0)
    if (item) {
      setItem(item)
    }
  }, [id])

  return (
    <>
      {item ? (
        <main>
          
          <div className='container'>
            <section className='mainContent details'>
              
              <h1 className='title'>{item.title}</h1>

              {/* <div className='author'>
                <span>by</span>
                <img src={item.cover} alt='' />
                <p> {item.authorName} on</p>
                <label>{item.time}</label>
              </div> */}

              {/* <div className='social'>
                <div className='socBox'>
                  <i className='fab fa-facebook-f'></i>
                  <span>SHARE</span>
                </div>
                <div className='socBox'>
                  <i className='fab fa-twitter'></i>
                  <span>TWITTER</span>
                </div>
                <div className='socBox'>
                  <i className='fab fa-pinterest'></i>
                </div>
                <div className='socBox'>
                  <i className='fa fa-envelope'></i>
                </div>
              </div> */}

              <img className="singleimg" src={item.cover} alt='' />
              <div className='desctop'>
                <p>{item.title}</p>
                {/* {item.map((val) => {
                  return (
                    <>
                    </>
                  )
                })} */}
              </div>
              {/* {item.desc.map((val) => (
                <p>{val.para3}</p>
              ))} */}

              {/* <div className='descbot'>
                {item.details.map((data) => {
                  return (
                    <>
                      <h1>{data.title}</h1>
                      <p>{data.para1}</p>
                    </>
                  )
                })}
              </div>

              <div className='quote'>
                <i className='fa fa-quote-left'></i>
                {item.details.map((data) => (
                  <p>{data.quote}</p>
                ))}
              </div>

              <div className='desctop'>
                {item.details.map((data) => {
                  return (
                    <>
                      <p>{data.para2}</p>
                      <p>{data.para3}</p>
                    </>
                  )
                })}
              </div> */}
            </section>
            <section className='sideContent'>
              <SidePage />
            </section>
          </div>
          <br/>
          <div className="oneone">
            <SinglePageSlider />
            </div>
        </main>
      ) : (
        <h1>topilmadi</h1>
      )}
    </>
  )
}

export default PpostPage
